<template>
  <v-container fluid>
    <ProgressCircular :isLoaded="isLoaded" />
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title v-if="employee.user">{{ employee.user.first_name }} {{ employee.user.last_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка співробітника</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isEmployeeExist"
              :is="item.component"
              :employee="employee"
              :isDataSaved="isDataSaved"
              :currentTab="currentTab"
              :editCard="editCard"
              @saveData="saveData"
            ></component>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn @click="editCard = !editCard" class="mr-2" small>
                  {{ !editCard ? 'Редагувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-5" flat>
      <Logs v-if="employee.user" :preselected-users="[employee.user]" disable-users />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import { STAFF_SCHEMA } from '@/const/apiSchemas'
import BackButton from '@/components/common/BackButton.vue'
import Logs from '@/components/common/Logs'

export default {
  name: 'StaffCard',

  components: {
    Logs,
    BackButton,
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    GeneralInfo: () => import('@/components/administration/staff/GeneralInfo'),
  },

  data() {
    return {
      showModal: false,
      currentTab: null,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      defaultItem: { ...STAFF_SCHEMA },
      tabs: [
        {
          title: 'Загальна інфо',
          component: 'GeneralInfo',
        },
      ],
    }
  },

  computed: {
    ...mapState('staff', ['employee']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentStaffId() {
      return this.$route.params.staffId
    },
    isEmployeeExist() {
      return !!Object.entries(this.employee).length
    },
    showEditButtons() {
      return this.currentTab === 0
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_EMPLOYEE({})
  },
  methods: {
    ...mapActions('staff', ['loadSelectedEmployee', 'updateSelectedEmployee']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('staff', ['SET_SELECTED_EMPLOYEE']),
    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          staffId: this.currentStaffId,
        }
        await this.loadSelectedEmployee(payload)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(updatedEmployee) {
      updatedEmployee = mergeObjectsDeep(this.defaultItem, updatedEmployee)
      const payloadForUpdate = {
        companyId: this.currentCompanyId,
        staffId: this.currentStaffId,
        updatedEmployee,
      }
      this.showModal = false
      this.isLoaded = false
      await this.updateSelectedEmployee(payloadForUpdate)
      // await this.initialize()
      this.isDataSaved = false
      this.isLoaded = true
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
